/* cadastro.css */

body {
  font-family: 'Nunito', sans-serif;
  background-color: #f5f5f5;

}

.cadastro-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: 20px auto;
}

.title {
  font-size: 1.5rem;
  color: #00abb9;
  margin-bottom: 1rem;
}

label {
  display: block;
  text-align: left;
  margin: 1rem 0 0.5rem 0;
  font-weight: 600;
  color: #333;
  font-size: 0.875rem;
}

input, select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.button-container button,
button.submit-button {
  background: linear-gradient(to right, #00abb9, #4169e1); /* Gradiente entre azul e verde */
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 0.75rem 1rem !important;
  font-size: 1rem !important;
  cursor: pointer !important;
  width: 100% !important;
}

.button-container button:hover,
button.submit-button:hover {
  background: linear-gradient(to right, #007f8a, #3b4eb9); /* Gradiente escuro entre azul e verde */
}

.table {
  margin-top: 1rem;
  font-size: 0.75rem;
  font-family: 'Nunito', sans-serif;
}

.table th, .table td {
  vertical-align: middle;
  font-size: 0.75rem;
  font-family: 'Nunito', sans-serif;
}

.alert {
  text-align: left;
}

.error {
  color: red;
  font-size: 0.75rem;
  margin-top: -0.75rem;
  margin-bottom: 0.75rem;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  width: calc(50% - 10px);
  margin-bottom: 20px;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
}

.rounded-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient-button {
  background: linear-gradient(to right, #007BFF, #00C6FF);
  border: none;
  color: white;
}

.modal-100w {
  max-width: 100%;
}