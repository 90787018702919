/* App.css */

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  background-color: #00abb9; /* Cor principal da Leiturinha */
  color: white;
  padding: 20px;
  text-align: center;
}

.header img {
  background-color: white; /* Fundo branco na logo principal */
  padding: 10px;
  border-radius: 8px;
}

.menu-content-container {
  display: flex;
  flex: 1;
}

.menu-lateral {
  background: linear-gradient(to bottom, #00abb9, #4169e1); /* Gradiente com cores da Leiturinha */
  width: 200px; /* Aumentei a largura para melhor visualização */
  padding: 20px;
}

.menu-lateral ul {
  list-style-type: none;
  padding: 0;
}

.menu-lateral li {
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
}

.menu-lateral li a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 10px; /* Ajustei o tamanho da fonte para melhor legibilidade */
}

.menu-lateral li:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 5px;
}

.menu-lateral li img {
  width: 30px;
  margin-right: 10px;
}

.menu-lateral li span {
  margin-left: 10px;
  font-weight: bold;
  font-size: 12px;
}

.content {
  flex: 1;
  padding: 20px;
}

.rodape {
  background-color: #4169e1; /* Azul */
  color: white;
  padding: 20px;
  text-align: center;
}
