/* Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to bottom, #00abb9, #4169e1);
  }
  
  .login-box {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px; /* Ajuste a largura para tornar a caixa mais quadrada */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .login-box img {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    width: 250px; /* Ajuste a largura da imagem conforme necessário */
    height: auto; /* Mantém a proporção da imagem */
    margin-bottom: 20px; /* Espaço entre a imagem e o restante do conteúdo */
  }
  
  .login-box form {
    width: 100%;
  }
  
  .login-box button {
    width: 100%;
    margin-top: 10px;
  }
  